import Typography from '@mui/material/Typography';


function Chart1desc() {
  return (

    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px'}} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
        
        <div style={{id:"chart1desc", textAlign: "Left" }}>
        
            <br />  
              <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">
              
              <span style={{ fontWeight: "bold" }}>What the chart represents: </span> 
              
                This chart measures the volatility of volatility, VIX, using VVIX.
                <br />
                <br />
                The VIX index is a measure of the expected volatility of the S&P 500 index over the next 30 days, based on the prices of SPX options. It is often called the “fear gauge” because it tends to rise when the market is uncertain or fearful, and fall when the market is confident or complacent.
                
                <br />
                <br />               
                
                The VVIX index is a measure of the expected volatility of the VIX index over the next 30 days, based on the prices of VIX options. It is often called the “volatility of volatility” because it reflects the uncertainty or variability of the VIX itself.
                
                <br />
                <br />  
                
                The relationship between the VIX and VVIX is complex and dynamic, but generally speaking, they tend to move in the same direction, especially when the VIX is high or spiking. This means that when the market expects higher volatility in the S&P 500, it also expects higher volatility in the VIX. However, there are also periods when the VIX and VVIX diverge or decouple, indicating different market expectations for the short-term and long-term volatility.
                
                <br />
                <br />  
                
                {'\u27A2'} &nbsp; 
                

                If VVIX rises faster than VIX (or VIX drops faster than VVIX), it indicates a potential drop in overall volatility.  
                <br />
                <br />  
                
                {'\u27A2'} &nbsp;
                If VVIX drops faster than VIX (or VIX rises faster than VVIX), it indicates a potential increase in overall volatility. <br />
                
                <br />
                                
                <span style={{ fontStyle: 'italic' }}>
                  *This chart updates on a 2-hourly basis, whenever the data source gets updated.
                </span>
              
              </Typography>
            <br/>
        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default Chart1desc;
