
//Pages
//import PageC1VVR from './PageC1VVR.js';
//import PageC2ZSC from './PageC2ZSC.js';
//import PageC3PCR from './PageC3PCR.js'; 
import PageD1TradeSystemFull from './PageD1TradeSystemFull.js'; 



{/* 
comments 
*/}

const PageTradeSystem = () => {
return (
    
    <div id="TradeSystem">
       <PageD1TradeSystemFull />
        
    </div>
        
  );
};
export default PageTradeSystem;
