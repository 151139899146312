import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function TradeSystemContent2() {
  return (
        
       
    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
         
        
        <div style={{id:"TradeSystem2desc", textAlign: "Left", justifyContent: "center" }}>

            <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15  } }}textAlign="justify">

                <br /> 
                <br /> 
                <span style={{ fontWeight: "bold" }}>Analysis:</span> Based on the table, we evaluated 5 potential systems based on a few critiera/factors.
                <br />                  
                <br />
                <br />
                {'\u27A2'} &nbsp;                
                <span style={{ fontWeight: "bold" }}>On risk:</span>                
                <br />
                <br />  
                
                <span style={{ fontWeight: "bold" }}>Length of period held:</span>  Ideally, we want to hold risk as short but as frequent as possible. By reducing our holding duration (start to end), we lower our risk.
                <br />
                <br />  
                
                <span style={{ fontWeight: "bold" }}>Days to expiration (DTE):</span> As front month VIX futures  contracts are more volatile/sensitive to index/spot changes, we try to avoid it as much as possible.
                
                <br />
                <br /> 
                 
                <span style={{ fontWeight: "bold" }}>Drawdowns: </span> Measures of days with sudden VIX spikes or prolong periods of volatility. In this case we use the average of 10 worst days, excluding entire year of 2020. (we think that 2020's VIX value is skewed and by excluding that, we can measure periods outside of 2020.
                <br />
                <br />               
                <br />
                {'\u27A2'} &nbsp;                
                <span style={{ fontWeight: "bold" }}>On reward:</span>
                
                <br />
                <br />  
                
                <span style={{ fontWeight: "bold" }}>Average returns:</span> The average returns give a good gauge what can be expected. Not statistically the best way, but it provides a means of filtering.
                <br />
                <br /> 
                              
                 <span style={{ fontWeight: "bold" }}>Average open/close prices:</span> There are multiple usage. For eg. (1) Closing prices tend to be higher nearer to end DTE periods (near term contracts). (2) We can use it as a basis for entering or closing new positions, if the prices are higher/lower than usual. Again not statistically the best way to measure, but a good enough sensing. 
                <br />
                <br />  
                <br /> 
                We then extracted these 5 systems (highlighted in blue) and plotted their cumulative PnL returns,either with a higher reward to risk ratio, or to see if there is any differences on selecting ones with a shorter period and higher DTE over the other:
                
                <br />
                <br /> 

                {'\u27A2'} &nbsp;  
                2 mth spreads, selling at 100 DTE and closing at 50 DTE       
                <br />
                
                {'\u27A2'} &nbsp; 
                2 mth spreads, selling at 90 DTE and closing at 40 DTE  
                <br />
                
                {'\u27A2'} &nbsp;
                1 mth spread, selling at 90 DTE and closing at 60 DTE                 
                <br />
                
                {'\u27A2'} &nbsp;
                1 mth spread, selling at 85 DTE and closing at 55 DTE                    
                <br />
                
                {'\u27A2'} &nbsp;
                1 mth spread, selling at 70 DTE and closing at 40 DTE                    
                <br />
                <br />                
                <br /> 
                The results offered some insights, such as:
                <br /> 
                - drawdown risk with a 2 month spread system (to be fair, it had 2 positions at any one time, but rewards did not perform twice as well).
                <br />                 
                - 1-month spread performance was not significantly higher when positions were closed nearer to a lower DTE (ie. higher risk, higher returns).
                <br />                 
                - to confirm if better performance can be achieved (due to lower drawdown risk) when a position is closed earlier vs later, conforming to the front month volatile/sensitive theory.
                
              </Typography>
            <br/>
        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default TradeSystemContent2;
