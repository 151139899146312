import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse


function AnalysisVVratio1 () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  
  const [ last30thRow, setLast30thRow ] = useState (null);
  const [ last20thRow, setLast20thRow ] = useState (null);
  const [ last10thRow, setLast10thRow ] = useState (null);
  const [ last5thRow, setLast5thRow ] = useState (null);
  
  const [ last30thVVratio, setLast30thVVratio ] = useState (null);  
  const [ last20thVVratio, setLast20thVVratio ] = useState (null);  
  const [ last10thVVratio, setLast10thVVratio ] = useState (null);  
  const [ last5thVVratio, setLast5thVVratio ] = useState (null);  
  const [ lastVVratio, setLastVVratio ] = useState (null);

  
  
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VVRatio.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        
        // Get the last X row from the data        
        const last30th = results.data[results.data.length - 31];
        const last20th = results.data[results.data.length - 21];
        const last10th = results.data[results.data.length - 11];
        const last5th = results.data[results.data.length - 6];
        const lastrow = results.data[results.data.length - 2];
        //const last = results.data[results.data.length - 2];
        
        // Set the last row to the state  
        // Set the rows to the state
        setLast30thRow (last30th);
        setLast20thRow (last20th);
        setLast10thRow (last10th);
        setLast5thRow (last5th);
        setLastRow (lastrow);
        
        setLast30thVVratio (last30th["VVRatio"]);
        setLast20thVVratio (last20th["VVRatio"]);
        setLast10thVVratio (last10th["VVRatio"]);
        setLast5thVVratio (last5th["VVRatio"]);
        
        
        // Set the last known VVratio to the state
        setLastVVratio (lastrow["VVRatio"]);
        
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  
  
  // Define a function to render the table with the last row data
  const RenderAnalysis_VVratio1 = () => {
    // Check if the last row exists
        
    if (lastRow) {
        
      // Define a function to return the daily move


      // Initialize an empty message variable
      let message = "";
      

      
      //Testing

      //let lastVVratio = 6.16
      //let last5thVVratio = 6.4
      //let last10thVVratio = 6.8
      //let last20thVVratio = 6.5
      //let last30thVVratio = 6.7
      
      //Types of trend ana;ysis:
      // 1.Strong trend
      // 2.Ongoing trend
      // 3.Possible new trend
      // 4.Mini breakout
      // 5.strong reversal
      
      // Use a switch statement to assign a custom message based on the VIX range
      switch (true) {
        //Strong trend
        // 1<2<3<4<X
        case last30thVVratio <= last20thVVratio && last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last5thVVratio <= lastVVratio:
          message = "on a strong uptrend in the past 30 days";
          break;
          
        // 5>4>3>2>X
        case last30thVVratio >= last20thVVratio && last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last5thVVratio >= lastVVratio:
          message = "on a strong downtrend in the past 30 days";
          break;
        
        //ongoing trend
        // 1<2<3<4 X, 3<X<4
        case last30thVVratio <= last20thVVratio && last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last10thVVratio <= lastVVratio && lastVVratio <= last5thVVratio:
          message = "on an ongoing uptrend";
          break;
        // 5>4>3>2 X, 3>X>2
        case last30thVVratio >= last20thVVratio && last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last10thVVratio >= lastVVratio && lastVVratio >= last5thVVratio:
          message = "on an ongoing downtrend";
          break;
        
        //possible new trend
        // X 1<2<3<4  
        case last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last5thVVratio <= lastVVratio:
          message = "heading towards an uptrend with today's data";
          break;
        // X 4>3>2>X   
        case last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last5thVVratio >= lastVVratio:
          message = "heading towards a downtrend with today's data";
          break;
        
        //Mini breakout
        // 1<X, 2<X, 3<X, 4<X 
        case last30thVVratio <= lastVVratio && last20thVVratio <= lastVVratio && last10thVVratio <= lastVVratio && last5thVVratio <= lastVVratio:
          message = "higher than all recent datapoints in the past 30 days";
          break;
          
        // 1>X, X>2, X>3, X>4        
        case last30thVVratio >= lastVVratio && last20thVVratio >= lastVVratio && last10thVVratio >= lastVVratio && last5thVVratio >= lastVVratio:
          message = "lower than all recent datapoints in the past 30 days";
          break;
        
        
        //strong reversal
        // 1<Y<Y<4 X, X<1
        case last30thVVratio <= last5thVVratio && lastVVratio <= last30thVVratio:        
          message = "indicating a trend reversal with today's data vs 30 days ago ";
          break;
        // 5>Y>Y>2 X, X>5 
        case last30thVVratio >= last5thVVratio && lastVVratio >= last30thVVratio :
          message = "indicating a trend reversal with today's data vs 30 days ago ";
          break;
        
        
        default:
          message = "not taking any directions in the past 30 days";
      }
      
      
      let message2 = "";
      
      // Use a switch statement to assign a custom message based on the VIX range

      switch (true) {
        //Strong trend
        // 1<2<3<4<5
        case last30thVVratio <= last20thVVratio && last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last5thVVratio <= lastVVratio:
          message2 = "an established trend of decreasing volatility, either VVIX is going higher or VIX is going lower (VVIX/VIX)";
          break;
        // 5>4>3>2>1         
        case last30thVVratio >= last20thVVratio && last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last5thVVratio >= lastVVratio:
          message2 = "an established trend of increasing volatility, either VVIX is going lower or VIX is going higher (VVIX/VIX)";
          break;
          
        //ongoing trend
        // 1<2<3<4 X, 3<X<4
        case last30thVVratio <= last20thVVratio && last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last10thVVratio <= lastVVratio && lastVVratio <= last5thVVratio:
          message2 = "an possible early warning signal that volatility is dropping ahead.";
          break;
        // 5>4>3>2 X, 3>X>2
        case last30thVVratio >= last20thVVratio && last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last10thVVratio >= lastVVratio && lastVVratio >= last5thVVratio:
          message2 = "an possible early warning signal that volatility is rising ahead.";
          break;  
          
        // X<2<3<4           
        case last20thVVratio <= last10thVVratio && last10thVVratio <= last5thVVratio && last5thVVratio <= lastVVratio:
          message2 = "an possible early warning signal that volatility is about to go lower.";
          break;
        // X>3>2>1  
        case last20thVVratio >= last10thVVratio && last10thVVratio >= last5thVVratio && last5thVVratio >= lastVVratio:
          message2 = "an possible early warning signal that volatility is about to go higher.";
          break;
        
        //Mini breakout
        // 1<X, 2<X, 3<X, 4<X 
        case last30thVVratio <= lastVVratio && last20thVVratio <= lastVVratio && last10thVVratio <= lastVVratio && last5thVVratio <= lastVVratio:
          message2 = "a possible shift in market.";
          break;
          
        // 1>X, X>2, X>3, X>4        
        case last30thVVratio >= lastVVratio && last20thVVratio >= lastVVratio && last10thVVratio >= lastVVratio && last5thVVratio >= lastVVratio:
          message2 = "a possible shift in market.";
          break;
          
        //strong reversal
        // 1<Y<Y<4 X, X<1
        case last30thVVratio <= last5thVVratio && lastVVratio <= last30thVVratio:        
          message2 = "a possible reversal in market.";
          break;
        // 5>Y>Y>2 X, X>5 
        case last30thVVratio >= last5thVVratio && lastVVratio >= last30thVVratio :
          message2 = "a possible reversal in market.";
          break;        
          
        default:
          message2 = "both the VVIX and VIX are fluctuating and no signs of a clear trend yet";
      }
      
      // Return the div element with the data and the message  
      
      
      
      return (

        <div>
     
        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">                              
            <span style={{ fontWeight: "bold" }}>{'\u2727'} &nbsp; VVratio Analysis: </span> 
                              
            Comparing recent VVratio data, VVRATIO (<u style={{ fontWeight: "bold",color: "Crimson" }}>{lastRow["VVRatio"]}</u>) is <span style={{ fontWeight: "bold", color: "DarkGreen"  }}>{message}</span>, suggesting <span style={{ fontWeight: "bold", color: "DarkGreen" }}>{message2}</span>

        </Typography>        
        
        </div>

   
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return RenderAnalysis_VVratio1();
}
export default AnalysisVVratio1


