import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function DisplayChart3aData () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./cboeVixPCRratio.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        
        //console.table (results.data);
        
        // Get the last row from the data
        const last = results.data[results.data.length - 2];
        // Set the last row to the state
        setLastRow (last);
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
    
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the last row data
  const renderTable = () => {
    // Check if the last row exists
    if (lastRow) {
        
      // Return the table element with the data
      return (

        //<table style={{ borderCollapse: 'collapse', width: '80%', height: '80px'}} border='2px solid black'>
        <table style={{ borderCollapse: 'collapse', width: '70%', height: '60px'}} >
          <thead>
            <tr>
                <th style={{ backgroundColor: 'ForestGreen', color: 'white', width: '25%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                    Date        
                    </Typography>
                </th>
                
                <th style={{ backgroundColor: 'ForestGreen', color: 'white', width: '25%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                    VIX Call Opt        
                    </Typography>            
                </th>
                <th style={{ backgroundColor: 'ForestGreen', color: 'white', width: '25%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                    VIX Put Opt        
                    </Typography>
                </th>
                <th style={{ backgroundColor: 'ForestGreen', color: 'white', width: '25%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                    VIX PCR        
                    </Typography>
                </th>

            </tr>
          </thead>
          <tbody>
            <tr>

                <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 12, md: 14, lg: 16 } }}>              
                        {lastRow["date"]}
                    </Typography>              
                </td>
                
                <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                
                        {lastRow["VIXCallVol"]}
                    </Typography>              
                </td>
                
                <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                 
                        {lastRow["VIXPutVol"]}
                    </Typography>
                </td>
                
                <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                 
                        {lastRow["VIXpcr"]}
                    </Typography>              
                </td>                
            </tr>
          </tbody>
        </table>

        
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return renderTable ();
}
export default DisplayChart3aData;
