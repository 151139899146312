import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
function TradeSystemTable1() {
  return (


    <div style={{id:"TradeSystemResults-desc", textAlign: "center", justifyContent: "center" }}>

        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 10, md: 12, lg: 14  } }}textAlign="justify">
                                
        <>

<table style={{border: "none", borderCollapse: "collapse", display: "flex", alignItems: "center", width: "400pt" }}>
  <tbody>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#C4BD97",
          height: "15.0pt",
          width: "10vmin"
        }}
      >
        Spread
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "10vmin"
        }}
      >
        Start
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "10vmin"
        }}
      >
        End
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "14vmin"
        }}
      >
        Total&nbsp;
        <br />
        &nbsp;CumPnL
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "10vmin"
        }}
      >
        Avg&nbsp;
        <br />
        &nbsp;Open&nbsp;
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "10vmin"
        }}
      >
        Avg&nbsp;
        <br />
        &nbsp;Close&nbsp;
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "10vmin"
        }}
      >
        Avg&nbsp;
        <br />
        &nbsp;PnL
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 700,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "1.0pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none",
          background: "#C4BD97",
          width: "16vmin"
        }}
      >
        Avg worst 10,
        <br />
        &nbsp;Excl 2020
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none"
        }}
      >
        110
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: "none"
        }}
      >
        50
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#DEF0E5"
        }}
      >
        36.53
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.480
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.776
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#DEF0E5"
        }}
      >
        0.295
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FEE282"
        }}
      >
        -3.326
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        110
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        45
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#DFF0E6"
        }}
      >
        36.36
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.480
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.775
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#DFF0E6"
        }}
      >
        0.293
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FDC77D"
        }}
      >
        -3.490
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        110
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        40
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#91D1A3"
        }}
      >
        59.44
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.482
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.961
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#91D1A3"
        }}
      >
        0.479
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#F8696B"
        }}
      >
        -4.051
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        100
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        50
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E0F1E7"
        }}
      >
        35.87
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.484
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.776
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E0F1E7"
        }}
      >
        0.289
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#F3E884"
        }}
      >
        -3.130
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        100
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        45
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E1F1E8"
        }}
      >
        35.7
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.485
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.775
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E1F1E8"
        }}
      >
        0.288
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FEE382"
        }}
      >
        -3.323
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        100
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        40
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#93D2A5"
        }}
      >
        58.78
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.486
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.961
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#93D2A5"
        }}
      >
        0.474
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#F98670"
        }}
      >
        -3.873
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        90
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        50
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E6F3EC"
        }}
      >
        34.16
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.553
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.776
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#FCFCFF"
        }}
      >
        0.223
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#F4E884"
        }}
      >
        -3.135
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        90
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        45
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E7F4ED"
        }}
      >
        33.99
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.551
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.775
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#FCFCFF"
        }}
      >
        0.222
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FFEB84"
        }}
      >
        -3.278
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        90
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        40
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#99D4AA"
        }}
      >
        57.07
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.552
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.961
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#AFDDBD"
        }}
      >
        0.408
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FA9D75"
        }}
      >
        -3.739
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        80
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        50
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#FCFCFF"
        }}
      >
        27.67
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.494
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.776
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E6F3EC"
        }}
      >
        0.275
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#F4E884"
        }}
      >
        -3.135
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        80
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        45
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#FCFCFF"
        }}
      >
        27.5
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.494
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.775
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#E7F4ED"
        }}
      >
        0.274
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FFEB84"
        }}
      >
        -3.278
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#E4DFEC",
          height: "15.0pt"
        }}
      >
        2mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        80
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        40
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#AFDDBD"
        }}
      >
        50.58
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.495
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.961
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#99D4AA"
        }}
      >
        0.460
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#FA9D75"
        }}
      >
        -3.739
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#DAEEF3",
          height: "15.0pt",
          borderTop: "none"
        }}
      >
        1mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        90
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        60
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#8ED0A0"
        }}
      >
        60.5
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.280
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.767
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#8ED0A0"
        }}
      >
        0.488
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#63BE7B"
        }}
      >
        -1.376
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#DAEEF3",
          height: "15.0pt",
          borderTop: "none"
        }}
      >
        1mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        85
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#C5D9F1",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        55
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#87CD9A"
        }}
      >
        62.33
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.302
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.806
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#87CD9A"
        }}
      >
        0.503
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#82C77D"
        }}
      >
        -1.752
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#DAEEF3",
          height: "15.0pt",
          borderTop: "none"
        }}
      >
        1mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        80
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        50
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#90D0A2"
        }}
      >
        59.89
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.293
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.776
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#90D0A2"
        }}
      >
        0.483
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#98CE7F"
        }}
      >
        -2.015
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#DAEEF3",
          height: "15.0pt",
          borderTop: "none"
        }}
      >
        1mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        75
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        45
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#AFDDBC"
        }}
      >
        50.7
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.366
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none"
        }}
      >
        0.775
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          borderTop: "none",
          borderLeft: "none",
          background: "#AFDDBC"
        }}
      >
        0.409
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: ".5pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#B8D780"
        }}
      >
        -2.409
      </td>
    </tr>
    <tr>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: ".5pt solid windowtext",
          background: "#DAEEF3",
          height: "15.75pt",
          borderTop: "none"
        }}
      >
        1mth
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: "none",
          background: "#C5D9F1"
        }}
      >
        70
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: "none",
          background: "#C5D9F1"
        }}
      >
        40
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#63BE7B"
        }}
      >
        73.02
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: "none"
        }}
      >
        0.370
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: "none",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: "none"
        }}
      >
        0.961
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: ".5pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#63BE7B"
        }}
      >
        0.589
      </td>
      <td
        style={{
          color: "black",
          fontWeight: 400,
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
          borderTop: ".5pt solid windowtext",
          borderRight: "1.0pt solid windowtext",
          borderBottom: "1.0pt solid windowtext",
          borderLeft: ".5pt solid windowtext",
          background: "#CFDD82"
        }}
      >
        -2.684
      </td>
    </tr>
  </tbody>
</table>



        </>
        </Typography>

    </div>
        

  );
}
export default TradeSystemTable1;
