import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
function TradeSystemTable3() {
  return (


    <div style={{id:"TradeSystemResults-desc", textAlign: "center", justifyContent: "center" }}>

        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 10, md: 12, lg: 14  } }}textAlign="justify">
                                
        <>
          <p>&nbsp;</p>

          <table style={{ border: "none", borderCollapse: "collapse", display: "flex", alignItems: "center", width: "300px",  }}>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "15.0pt",
                    width: "10vmin"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Jan
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Feb
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Mar
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Apr
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  May
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Jun
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Jul
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Aug
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Sep
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Oct
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Nov
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "1.0pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "none",
                    width: "10vmin"
                  }}
                >
                  Dec
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2013
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#7FCA93"
                  }}
                >
                  1.48%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#EDF6F2"
                  }}
                >
                  0.57%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#DAEFE2"
                  }}
                >
                  0.73%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF9FC"
                  }}
                >
                  0.35%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#A3D8B2"
                  }}
                >
                  1.19%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF5F8"
                  }}
                >
                  0.14%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#C1E4CC"
                  }}
                >
                  0.94%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#ACDCBA"
                  }}
                >
                  1.11%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#AEDDBC"
                  }}
                >
                  1.09%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2014
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D4ECDC"
                  }}
                >
                  0.78%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF6F9"
                  }}
                >
                  0.18%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#CAE8D4"
                  }}
                >
                  0.87%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F8FBFC"
                  }}
                >
                  0.48%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F8FBFC"
                  }}
                >
                  0.48%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D5EDDE"
                  }}
                >
                  0.77%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#B6E0C2"
                  }}
                >
                  1.03%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF3F6"
                  }}
                >
                  0.04%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E5F3EB"
                  }}
                >
                  0.64%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.42%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.42%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#E8F4EE"
                  }}
                >
                  0.61%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2015
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF6F9"
                  }}
                >
                  0.21%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF3F6"
                  }}
                >
                  0.04%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.42%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.41%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#DFF1E6"
                  }}
                >
                  0.69%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F0F8F5"
                  }}
                >
                  0.55%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F5F9F9"
                  }}
                >
                  0.51%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#CBE8D5"
                  }}
                >
                  0.85%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FABCBF"
                  }}
                >
                  -2.46%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#ADDCBB"
                  }}
                >
                  1.10%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E8F4EE"
                  }}
                >
                  0.61%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FCFCFF"
                  }}
                >
                  0.45%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2016
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF7FA"
                  }}
                >
                  0.24%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBE4E7"
                  }}
                >
                  -0.62%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F0F7F5"
                  }}
                >
                  0.55%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D1EBDA"
                  }}
                >
                  0.81%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.42%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E6F3EC"
                  }}
                >
                  0.63%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#DFF0E6"
                  }}
                >
                  0.69%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#CCE9D6"
                  }}
                >
                  0.84%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#C7E7D2"
                  }}
                >
                  0.88%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#AFDDBD"
                  }}
                >
                  1.09%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FCFCFF"
                  }}
                >
                  0.45%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#B0DEBE"
                  }}
                >
                  1.08%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2017
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#97D3A8"
                  }}
                >
                  1.29%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#C4E6CF"
                  }}
                >
                  0.91%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.43%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D8EEE0"
                  }}
                >
                  0.75%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E8F4EE"
                  }}
                >
                  0.61%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#B3DFC0"
                  }}
                >
                  1.05%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF8FB"
                  }}
                >
                  0.31%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.44%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF4F7"
                  }}
                >
                  0.09%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#9AD5AB"
                  }}
                >
                  1.26%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF7FA"
                  }}
                >
                  0.25%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#D9EEE0"
                  }}
                >
                  0.74%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2018
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#DAEFE2"
                  }}
                >
                  0.73%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF7FA"
                  }}
                >
                  0.24%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBE8EB"
                  }}
                >
                  -0.45%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF8FB"
                  }}
                >
                  0.30%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF9FC"
                  }}
                >
                  0.32%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#EDF6F2"
                  }}
                >
                  0.57%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF0F3"
                  }}
                >
                  -0.08%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFAFD"
                  }}
                >
                  0.38%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF6F9"
                  }}
                >
                  0.21%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.41%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEDF0"
                  }}
                >
                  -0.20%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBF5F8"
                  }}
                >
                  0.17%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2019
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBE0E3"
                  }}
                >
                  -0.81%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#EBF5F0"
                  }}
                >
                  0.59%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF8FB"
                  }}
                >
                  0.27%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF1F4"
                  }}
                >
                  -0.01%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFAFD"
                  }}
                >
                  0.37%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF4F7"
                  }}
                >
                  0.11%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF8FB"
                  }}
                >
                  0.28%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF3F6"
                  }}
                >
                  0.05%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF4F7"
                  }}
                >
                  0.10%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E4F3EA"
                  }}
                >
                  0.65%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#DAEFE2"
                  }}
                >
                  0.73%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBFAFD"
                  }}
                >
                  0.38%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2020
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FCFCFF"
                  }}
                >
                  0.45%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEFF2"
                  }}
                >
                  -0.12%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEBEE"
                  }}
                >
                  -0.30%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F8696B"
                  }}
                >
                  -6.28%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#EDF6F2"
                  }}
                >
                  0.57%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFBFE"
                  }}
                >
                  0.42%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#63BE7B"
                  }}
                >
                  1.71%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBD8DB"
                  }}
                >
                  -1.18%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBE8EB"
                  }}
                >
                  -0.43%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF3F6"
                  }}
                >
                  0.06%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBDDE0"
                  }}
                >
                  -0.93%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#85CC99"
                  }}
                >
                  1.43%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2021
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBE7EA"
                  }}
                >
                  -0.48%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEEF1"
                  }}
                >
                  -0.16%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#7BC890"
                  }}
                >
                  1.51%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#B3DFC0"
                  }}
                >
                  1.06%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D9EEE1"
                  }}
                >
                  0.74%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F5F9F9"
                  }}
                >
                  0.51%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#CDE9D7"
                  }}
                >
                  0.84%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBFAFD"
                  }}
                >
                  0.37%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#D8EEE0"
                  }}
                >
                  0.74%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF1F4"
                  }}
                >
                  -0.05%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#9BD5AB"
                  }}
                >
                  1.25%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBF5F8"
                  }}
                >
                  0.13%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2022
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#82CB96"
                  }}
                >
                  1.46%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEEF1"
                  }}
                >
                  -0.16%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBDEE0"
                  }}
                >
                  -0.93%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#82CB96"
                  }}
                >
                  1.46%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF9FC"
                  }}
                >
                  0.34%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#E1F1E8"
                  }}
                >
                  0.67%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBEEF1"
                  }}
                >
                  -0.15%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#F0F7F4"
                  }}
                >
                  0.55%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF4F7"
                  }}
                >
                  0.12%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF9FC"
                  }}
                >
                  0.33%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: ".5pt solid windowtext",
                    borderTop: "none",
                    borderLeft: "none",
                    background: "#FBF4F7"
                  }}
                >
                  0.12%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: ".5pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#E5F3EC"
                  }}
                >
                  0.64%
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "1.0pt solid windowtext",
                    height: "18.0pt",
                  }}
                >
                  2023
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#F2F8F7"
                  }}
                >
                  0.53%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBF9FC"
                  }}
                >
                  0.35%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBF4F7"
                  }}
                >
                  0.12%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#FBFAFD"
                  }}
                >
                  0.38%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#D6EDDE"
                  }}
                >
                  0.76%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: ".5pt solid windowtext",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: ".5pt solid windowtext",
                    background: "#D3ECDC"
                  }}
                >
                  0.79%
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: ".5pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
                <td
                  style={{
                    color: "black",
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "none",
                    borderTop: "none",
                    borderRight: "1.0pt solid windowtext",
                    borderBottom: "1.0pt solid windowtext",
                    borderLeft: "none"
                  }}
                >
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <br />
          </p>
        </>
            
        </Typography>

    </div>
        

  );
}
export default TradeSystemTable3;
