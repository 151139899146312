// import React and Chart from the react-google-charts library
import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

//import { ResponsiveContainer } from 'recharts';



{/* 
comments 
*/}


// define a function that takes a chart name as a parameter

export const Chart2 = (chartName) => {
  // use useState hook to store the data for the chart
  const [data, setData] = useState([]);
  // define a function that fetches the data from the json files
  const fetchData = async () => {
    try {
        const response = await fetch("./VIXSpreadZscore.csv");
        
        const data = await response.text();
        // convert the data to the format required by Google Charts
        
        const chartData = [
        ["day","Zs1","Zs2","Zs3","Zs4","Zs5"],
        ];
        
        const lines = data.split("\n");
        
        // get the number of records
        const n = lines.length;
        // get the index of the first record to display
        const firstIndex = n - 31;
        
        for (let i = firstIndex; i < n; i++) {
            // split the line by commas
            const values = lines[i].split(",");
            
            // parse the values as numbers
            const day = values[0];
            const Zscore1 = Number(values[16]);
            const Zscore2 = Number(values[17]);
            const Zscore3 = Number(values[18]);
            const Zscore4 = Number(values[19]);
            const Zscore5 = Number(values[20]);

            
            // convert the day to a Date object
            const dateObj = new Date(day);
            
            // push the data to the chartData array

            chartData.push([dateObj,Zscore1, Zscore2, Zscore3, Zscore4,Zscore5]);
        }

        // set the data state to the chart data
        setData(chartData);
        } catch (error) {
      console.error(error);
    }
  };
  // use useEffect hook to call the fetch data function when the component mounts
  useEffect(() => {
    fetchData();
  }, []);




  // return the JSX code for the chart component
    return (
        <Chart
            width={"90vw"}
            height={"60vh"}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={data}

            
            options={{
               
                title: "Relative Zscore for 5 Vix spreads for past 30days" , 
                Position: 'top',
                //titlePosition: 'in', 
                
                titleTextStyle: {
                fontSize: 12,
                color: 'black', 
                fontName: 'Merriweather'
                //bold: true, 
                //italic: false
                },
                backgroundColor: '#edf3f5', 
                
                hAxis: {
                  title: "Date",
                  titleTextStyle: { color: "#333" },
                  format: 'MMM dd' 
                },
                
                vAxis: { 
                  minValue: 0,
                  textPosition: 'in',  //text appears on chart itself
                  gridlines: {
                    strokeDasharray: '3 3'  // Set dashed gridlines
                  }

                },

                chartArea: { alignment: "center", width: "80%", height: "60%" },
                
                isStacked: false, 
                interpolateNulls: true, 
                // add the seriesType and series options here
                
                chartType: "ComboChart", // set the chart type to combo

                //seriesType: "line", // set the default type to line

                series: {
                  0: { type: "line" , lineWidth: 2,  pointSize: 2, pointShape: 'circle' }, 
                  1: { type: "line" , lineWidth: 2,  pointSize: 2, pointShape: 'circle' }, 
                  2: { type: "line", lineWidth: 2,  pointSize: 2, pointShape: 'circle' }, 
                  3: { type: "line" , lineWidth: 2, pointSize: 2, pointShape: 'circle' },  
                  4: { type: "line" , lineWidth: 2, pointSize: 2, pointShape: 'circle' },



                },
                
                colors: ["IndianRed", "SandyBrown", "MediumPurple", "SteelBlue", "MediumSeaGreen"],
                
                
                /*
                trendlines: {
                  0: {
                    type: 'polynomial',
                    degree: 40,
                    color: '48B8F0',
                    opacity: 1,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                  },
                },  
                
                /*
                  5: {
                    type: 'polynomial',
                    degree: 30,
                    color: 'BB453A',
                    opacity: 0.7,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                    //pointShape: 'circle',
                    //pointShape: ''square' ',
                    //pointShape: ''diamond' ',

                    
                  },                     
                },
                */

  
                legend: { 
                    position: "bottom", 
                    textStyle: { fontName: 'Merriweather', fontSize: '10'}, 
                    maxLines: 2,
                    legendToggle: false
                },
                
                curveType: 'function',
                animation: { startup: true, duration: 1000, easing: 'out' }

                }}
                                
        style={{ margin: "1px" }} 
        />
          
            //</ResponsiveContainer>
    );
};

