import Typography from '@mui/material/Typography';


function Chart2desc() {
  return (

    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
        
        <div style={{id:"chart2desc", textAlign: "Left", justifyContent: "center" }}>
            <br />  
              <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15  } }}textAlign="justify">
                            
              <span style={{ fontWeight: "bold" }}>What the Zscore chart represents: </span>   
                This chart measures the 5 different Z-score of illustrated expections of market volatility using SPX maturities (1st VIX1/ 2nd VIX as Zscore1, 2nd VIX/3rd VIX as Zscore2 and so on), providing a statistical measurement on the deviation (-3 to 3) for each VIX term spread, based on historical monthly adjusted mean (Jan 2024 vs Jan 2022 to 2012). If Z-score is 1.0, it is 1 standard deviation above the mean, meaning its "expensive". 0 would be just right, and negative means its "cheap". <br />
                <br />
                
                {'\u27A2'} &nbsp; 
                
                Example: If a VIX spread 2 (VIX2/VIX3) has a lower than "0" Zscore, it means the current spread may be relatively smaller than historical mean on the same period, same contract spread. One may consider buying this spread by selling 2nd month and buying 3rd month using VIX futures.  
                <br />
                <br />
                {'\u27A2'} &nbsp; 
                
                Also consider the near term VIX futures being more volatile, hence the zscore for first spread must be be higher than the next, and so on.
                <br />
                
                <br />               
                <span style={{ fontStyle: 'italic' }}>
                  *This chart updates on a 2-hourly basis, whenever the data source gets updated.
                  <br /> 
                  *The suggestion of buying/selling is for illustration only.
                </span>
              
              </Typography>
            <br/>
        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default Chart2desc;
