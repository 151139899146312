import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function DisplayChart2Data () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VIXSpreadZscore.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        //const results = Papa.parse (csvData, { header: true, newline: '\r\n' });
        
        const results = Papa.parse (csvData, { header: true });
        
        //console.table (results.data);

        // Get the last row from the data
        const last = results.data[results.data.length - 2];
        // Set the last row to the state
        setLastRow (last);
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the last row data
  const renderTable = () => {
    // Check if the last row exists
    if (lastRow) {
      // Return the table element with the data
      return (

        <table style={{ borderCollapse: 'collapse', width: '75%', height: '60px'}} >
          <thead>
            <tr>
                <th style={{ backgroundColor: 'ForestGreen', color: 'white', width: '15%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Date        
                    </Typography>
                </th>
                
                <th style={{ backgroundColor: 'IndianRed', color: 'white', width: '13%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Zscore1        
                    </Typography>            
                </th>
                <th style={{ backgroundColor: 'SandyBrown', color: 'white', width: '13%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Zscore2       
                    </Typography>
                </th>
                <th style={{ backgroundColor: 'MediumPurple', color: 'white', width: '13%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Zscore3        
                    </Typography>
                </th>
                 <th style={{ backgroundColor: 'SteelBlue', color: 'white', width: '13%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Zscore4        
                    </Typography>
                </th>
                <th style={{ backgroundColor: 'MediumSeaGreen', color: 'white', width: '13%', border: '1.5px solid #2A3F4D' }}>

                    <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>
                    Zscore5       
                    </Typography>
                </th>
            </tr>
          </thead>
          <tbody>

            <tr>
            
                <td style={{ width: '15%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>              
                        {lastRow["date"]}
                    </Typography>              
                </td>
                
                <td style={{ width: '13%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>                
                        {lastRow["zscore1"]}
                    </Typography>              
                </td>
                
                <td style={{ width: '13%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>                 
                        {lastRow["zscore2"]}
                    </Typography>
                </td>
                
                <td style={{ width: '13%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>                 
                        {lastRow["zscore3"]}
                    </Typography>
                </td>
                
                <td style={{ width: '13%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>                 
                        {lastRow["zscore4"]}
                    </Typography>
                </td> 
                
                <td style={{ width: '13%', border: '1.5px solid #2A3F4D'}} >
                    <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 9, sm: 11, md: 13, lg: 15 } }}>                 
                        {lastRow["zscore5"]}
                    </Typography>
                </td>
                
            </tr>
          </tbody>
        </table>
        
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return renderTable ();
}
export default DisplayChart2Data;
