import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function TradeSystemContent1() {
  return (
        
       
    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
         
        
        <div style={{id:"TradeSystem1desc", textAlign: "Left", justifyContent: "center" }}>

            <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15  } }}textAlign="justify">
                <br />  
                
                With VIX index and VIX futures, we explore some strategies on selling the spread on a regular/mechanical basis, with data supplied by CBOE (website: 
                <Link to="https://www.cboe.com/us/futures/market_statistics/historical_data/" target="_blank"> https://www.cboe.com/us/futures/market_statistics/historical_data/ </Link>
                <br />
                <br />                 
          
                {'\u27A2'} &nbsp; 

                <span style={{ fontWeight: "bold" }}>Method#1:</span>  Sell 2x 2-month spread on an interval. Ie. Sell 4th and buy 6th VIX contract and let it sit for 50-60days. Once the contract shifts into the 3rd and 5th contract, repeat selling 4th and 6th.
                <br />
                <br />  
                
                {'\u27A2'} &nbsp;
                <span style={{ fontWeight: "bold" }}>Method#2:</span>  Sell a 1-month spread on a 30day interval with expiry starting from 70 to 90 days and ending with 40-60 days. Ie. Sell 3rd and buy 4th VIX contract and let it sit for 30 days. Once the contract reaches a certain expiration, close positon and reopen a new set.
                <br />
                <br />
                
                With this, the data was collated and processed to test which parameter would work out.

                <br />
                <br />

              </Typography>

        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default TradeSystemContent1;
