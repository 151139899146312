import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import Navbar.jsx for the Navigation bar, same folder as App.js
import Navbar from './Navbar.js'; 

import Typography from '@mui/material/Typography';



//Pages
import MainHome from './PHome.js';
import PageDailyCharts from './PDailyCharts.js';
import PageTradeSystem from './PTradeSystem.js';
import Page0Summary from './PageSummary.js'; 
import PageC1VVR from './PageC1VVR.js';
import PageC2ZSC from './PageC2ZSC.js';
import PageC3PCR from './PageC3PCR.js'; 

import { Helmet } from 'react-helmet';


{/* 
comments 
*/}


const App = () => {
return (

<BrowserRouter>
<div className="application">


    <Helmet>
    
        <title>VixDaily.com</title>
        <meta name="description" content="This is a VIX dashboard with visualisation and charts of data obtained from various official sources" />
        <meta name="keywords" content="vix dashbard, vvix dashbard, volatility index dashbard, visual vvix and vix ratio, cboe volume, vix charting, cboe vlume visual charts, customise vix charts" />
      
        <style>{'body { background-color: #edf3f5; }'}</style>
    
    </Helmet>
        
    <div id="topbar">

        <div id="div-navbar" style={{backgroundImage: 'linear-gradient(to right,#70e0b0,#BCECE0,#FADCD9,#F9F1F0)', display: "table", width: "99.9%", height: "40px", position: "fixed", top: "0px", left: "0", zIndex: "1" }}>
       
        <Typography sx={{ fontFamily: "Bungee Spice", fontSize: { xs: 10, sm: 12, md: 13, lg: 16 } }}>
        
            <Navbar /> 
            
        </Typography>    
        </div>
    </div>
        
    <div id="parent">
        <h1>
        <div style={{ id:"div-title", textAlign: "center", color: "#000000",  paddingTop: "0px"}}>
            
            <Typography variant="h1" sx={{ fontFamily: "Rubik Doodle Shadow", fontWeight: "bold", fontSize: { xs: 36, sm: 48, md: 60, lg: 72 } }}>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
            VixDaily.com
            </Link>            
            </Typography>
            
            <Typography variant="h2" sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 11, sm: 13, md: 15, lg: 17 } }}>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
            A daily dose of VIX related analysis
            </Link>            
            </Typography>
            <br />
        </div>
        </h1>
        
        


{/* 
comments - All content page generated from here:
*/}

        <Routes>

          <Route path="/" element={<MainHome />} />
          <Route path="/Summary" element={<Page0Summary />} />
          <Route path="/DailyCharts" element={<PageDailyCharts />} />
          <Route path="/TradeSystem" element={<PageTradeSystem />} />     
          {/* ... other routes <Route path="/home" element={<MainHome />} /> */}
        </Routes>


{/* 
comments for footer
*/}
      
        <div style={{id:"div-footer", textAlign: "center", paddingLeft: "0px", color: "#6aa18a" }}>

            <Typography sx={{ fontFamily: "Roboto", fontSize: { xs: 12, sm: 16, md: 20, lg: 24 } }}>
            
            <span style={{ fontWeight: "bold"}}>VixDaily.com</span>  
            <span style={{ fontWeight: "bold"}}>{'\u00AE'} &nbsp; |</span>&nbsp;&nbsp; 
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Contact</span>
             
            </Typography>
            <br/>
        </div>
        <br/>
        
      
    </div>
    </div>

    
    </BrowserRouter>
  );
};
export default App;

//#Official colocode in names
// https://www.w3schools.com/tags/ref_colornames.asp


//Navbar colorgradient:
//Orig: #3CACAE,#BCECE0,#FADCD9,#F9F1F0
//New: #70e0b0,#BCECE0,#FADCD9,#F9F1F0

//https://www.canva.com/colors/color-palettes/easter-egg-hunt/
//#ECF87F,#E4A58F,#97D49B,#A6E2DC
//#A6E2DC,#97D49B,#E4A58F,#ECF87F

//https://www.canva.com/colors/color-palettes/petal-crush/
// #BFD7ED,#60A3D9,#0074B7,#003B73
// #003B73,#0074B7,#60A3D9,#BFD7ED


// Chart color paletters
// https://wpdatatables.com/data-visualization-color-palette/
// https://www.patternfly.org/charts/colors-for-charts/
// https://carbondesignsystem.com/data-visualization/color-palettes/
// https://www.learnui.design/tools/data-color-picker.html




// Positive / Negative color indicator:
//If green, else red.
// const color = percentage > 0 ? '#008A3E' : '#b30000';


//Menu header green:
//ForestGreen

//Menu header blue:
//#0E86D4



//BGcolor test:
//background-color: #edf3f5;

//ChartColortheme
//Brightyello,w bright blur and dark red
//colors: ["#FFC256", "#0E86D4", "#97150b"],


//yellow,green,red
//colors: ["#facf84", "#6bbf34", "#87342e"],

//grey,blue,purple
//colors: ["#9FB1BD", "#89c7f0", "#574cc2"],

//skin,lightpink,darkblue
//colors: ["#e581bf", "#f8be99", "#1953bf"],





//General color guide GoldmanSachs:
//https://design.gs.com/foundation/color-system
//Gray 20 #DCE3E8
//Gray 30 #C1CCD6
//Gray 40 #9FB1BD
//Gray 60 #5B7282
//Gray 80 #2A3F4D   [table border]


//Green #077D55
//Yellow #F5C518
//Red #D91F11
//DarkRed #97150b
//LighterDarkRed #87342e
//Blue #0D4EA6

//Orange #E86427
//Purple #AC71F0
//Cyan #279C9C
//Darkblue #1953bf
//Lime #52A31D
//Pink #CC1D92
//Aqua #48B8F0



//Unicode symbol
//https://www.toptal.com/designers/htmlarrows/symbols/



// // {'\u2727'} &nbsp;
// // {'\u2727'} &nbsp;

// // {'\u27A2'} &nbsp;
// // {'\u27A2'} &nbsp;



