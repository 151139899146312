
//Pages
import PageC1VVR from './PageC1VVR.js';
import PageC2ZSC from './PageC2ZSC.js';
import PageC3PCR from './PageC3PCR.js'; 




{/* 
comments 
*/}

const PageDailyCharts = () => {
return (
    
    <div id="DailyCharts">
        <PageC1VVR />
        <PageC2ZSC />
        <PageC3PCR />            

    </div>
        
  );
};
export default PageDailyCharts;
