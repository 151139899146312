import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DarkToggle } from './DMToggle.js';
   
   /*
      const logState = state => {
        	console.log('Toggled:', state)
        } 
   */
   
   
    /*  
        <td style={{ textAlign: "center", width: '1vmin' }} >
            <Link style={{ textDecoration: 'none', color: 'inherit'}} to="/VVIX-VIX-Ratio">VVRatio</Link>  
        </td>
        
        <td style={{ textAlign: "center", width: '1vmin' }} >
            <Link style={{ textDecoration: 'none', color: 'inherit'}} to="/VIX-ZScore">Zscore </Link>  
        </td>
        
        <td style={{ textAlign: "left", width: '20%' }} >
           <Link style={{ textDecoration: 'none', color: 'inherit'}} to="/PUT-CALL-Ratio">&nbsp;&nbsp;PCRRatio</Link>
        </td>   
   */
   
   
function Navbar() {
    const [isToggled, toggle] = useState(true);
    const logState = state => {}
return (
     


  <table style={{ float: 'center', height: '40px', width:'90vmin',paddingLeft:'1%',paddingRight:'1px' }} >
    <tbody>
      <tr>
        <td style={{ textAlign: "center",width: '1pt' }} >
            <Link style={{ textDecoration: 'none', display: "block",  color: 'inherit'}} to="/">Home</Link> 
        </td>
        
        <td style={{ textAlign: "center", width: '5px' }} >
            &nbsp;
        </td> 
        
        <td style={{ textAlign: "center", width: '5px'  }} >
            <Link style={{ textDecoration: 'none', display: "block", color: 'inherit'}} to="/DailyCharts">DailyCharts</Link>  
        </td>
        
        <td style={{ textAlign: "center", width: '5px' }} >
            &nbsp;
        </td> 
        
        <td style={{ textAlign: "center", width: '5px' }} >
            <Link style={{ textDecoration: 'none', display: "block", color: 'inherit'}} to="/TradeSystem">TradeSystem</Link>  
        </td>

        <td style={{ textAlign: "center", width: '5px' }} >
            &nbsp;
        </td> 
        
        <td style={{ fontSize: '16px', alignItems: "right", width: '1vw', paddingTop:'5px', marginright: "1px" }} >
            <DarkToggle
                label={isToggled ? "🔆" : "🌙"}
                toggled={isToggled}
                onClick={logState}
                toggle={toggle}
        	/>
        </td>   
        
        <td style={{ textAlign: "center", width: '20%' }} >
            &nbsp;
        </td> 
        
      </tr>
    </tbody>
  </table>
  );
}
export default Navbar;
