
import Typography from "@mui/material/Typography";

import { Chart3all } from "./Charts/ChartComponents3all.js";
import DisplayChart3pcrData from "./Info/Displaylatestdata3pcr.js"; 
import Get4Percentage3PCR from "./Info/Get4Percentage3PCR.js"; 

import Chart3desc from "./Info/Chart3desc.js"; 

import { Chart3a } from "./Charts/ChartComponents3a.js";
import DisplayChart3aData from "./Info/Displaylatestdata3a.js"; 

import { Chart3b } from "./Charts/ChartComponents3b.js";
import DisplayChart3bData from "./Info/Displaylatestdata3b.js"; 

import { Chart3c } from "./Charts/ChartComponents3c.js";
import DisplayChart3cData from "./Info/Displaylatestdata3c.js"; 



const PageC3PCR = () => {
return (
    
    <div id="PagePCR">
    
{/* 
comments - Chart3all 
*/} 

        <div style={{id:"Chart3alltitle", textAlign: "Left", textDecoration: "underline", paddingLeft: "10%" }}>
            
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            3. Chart - RELATIVE PUT/CALL RATIO CHART FOR VIX, SPX, EQ.
            
            </Typography>
        </div>
        

        <div style={{id:"Chart3pcr", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart3all chartName="PCR chart" />     
        </div>
        
        <br/>
        <br/>
        
        <div style={{id:"Chart3pcr-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

            <DisplayChart3pcrData />
            
        </div>
        
        <br/>

        <div style={{id:"Chart3-get4pct", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

            <Get4Percentage3PCR  />
          
        </div>
        
        <div style={{id:"chart3-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <Chart3desc />

        </div>
        
        <br/>
        <br/>
        <br/>
        
{/* 
comments - chart 2a 
*/}        

        <div style={{id:"Chart3atitle", textAlign: "Left", textDecoration: "underline", paddingLeft: "10%" }}>
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            3a. Chart - VIX OPTIONS TRADED AND VIX PUT CALL RATIO (PCR)     
            
            </Typography>
            
        </div>
                    
        <div style={{id:"Chart3a", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart3a chartName="VIX options traded and VIX Put Call Ratio" />
            
        </div>
        
        <br/>
        <br/>
        
        <div style={{id:"Chart3a-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

            <DisplayChart3aData />
            
        </div>
        
        
        <br/>
        <br/>
        <br/>
{/* 
comments - chart 2b 
*/}
        <div style={{id:"Chart3btitle", textAlign: "Left", textDecoration: "underline", paddingLeft: "10%" }}>
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            3b. Chart - SPX OPTIONS TRADED AND SPX PUT CALL RATIO (PCR)
            
            </Typography>
        </div>
        

        <div style={{id:"Chart3b", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart3b chartName="SPX options traded and SPX Put Call Ratio" />
        </div>
        
        <br/>
        
        <div style={{id:"Chart3b-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

          <DisplayChart3bData />
            
        </div>

        <br/>    
        <br/>
        <br/>
        
{/* 
comments - chart 2c
*/}

        <div style={{id:"Chart3ctitle", textAlign: "Left", textDecoration: "underline", paddingLeft: "10%" }}>
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            3c. Chart - EQUITY OPTIONS TRADED AND EQUITY PUT CALL RATIO (PCR)
            
            </Typography>            
        </div>
        

        <div style={{id:"Chart3c", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart3c chartName="Equity options traded and SPX Put Call Ratio" />
            
        </div>
        
        <br/>
        
        <div style={{id:"Chart3c-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

          <DisplayChart3cData />
            
        </div>
       
        <br/>    
        <br/>
        <br/>
        
    </div>
        
  );
};
export default PageC3PCR;
