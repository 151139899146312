import Typography from '@mui/material/Typography';


function Chart3desc() {
  return (

    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
        
        <div style={{id:"chart3desc", textAlign: "Left" }}>
            <br />  
            
              <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">
              
              <span style={{ fontWeight: "bold" }}>What the chart represents: </span> 
              
                This chart shows the relativity of 3 Put Call ratio. <br />
                
                <br />
                
                <span style={{ fontWeight: "bold" }}>
                {'\u27A2'} &nbsp; 
                VIX PCR: &nbsp; 
                </span> 
                Measures the ratio for number of puts and call options traded on SPX for the day   <br />
                
                <span style={{ fontWeight: "bold" }}>
                {'\u27A2'} &nbsp;   
                SPX PCR: &nbsp;  
                </span> 
                Measures the ratio for number of puts and call options traded on SPX for the day   <br />
                
                
                <span style={{ fontWeight: "bold" }}>
                {'\u27A2'} &nbsp;   
                EQ PCR: &nbsp; 
                </span> 
                Measures the ratio for number of puts and call options traded on Equities for the day   <br />
                
                <br />
                
                <span style={{ fontStyle: 'italic' }}>
                  *This chart updates on a 2-hourly basis, whenever the data source gets updated.
                </span>
                
              
              </Typography>
            <br/>
        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default Chart3desc;
