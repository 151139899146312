import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function Get4Percentage () {
  // Define the state variables for the last 30th, last 20th, last 10th, and last 5th rows
  const [ last30thRow, setLast30thRow ] = useState (null);
  const [ last20thRow, setLast20thRow ] = useState (null);
  const [ last10thRow, setLast10thRow ] = useState (null);
  const [ last5thRow, setLast5thRow ] = useState (null);
  const [ lastrow, setLastRow ] = useState (null);
  
  // Define a new state variable for the last known VVratio
  const [ lastVVratio, setLastVVratio ] = useState (null);
  
  

  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VVRatio.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        // Get the last 30th, last 20th, last 10th, and last 5th rows from the data
        const last30th = results.data[results.data.length - 31];
        const last20th = results.data[results.data.length - 21];
        const last10th = results.data[results.data.length - 11];
        const last5th = results.data[results.data.length - 6];
        const lastrow = results.data[results.data.length - 2];
        
        // Set the rows to the state
        setLast30thRow (last30th);
        setLast20thRow (last20th);
        setLast10thRow (last10th);
        setLast5thRow (last5th);
        
        // Set the last known VVratio to the state
        setLastVVratio (lastrow["VVRatio"]);
        
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the rows data
  const renderTable = () => {
    
    
    
    // Define a function to calculate the percentage change and the cell color    
    const getCellData = (row) => {
      // Check if the row exists
      if (row) {
        // Get the VVratio from the row
        const VVratio = row["VVRatio"];
        // Calculate the percentage change - this makes percentage negative if previous lower
        //const percentage = ((VVratio - lastVVratio) / lastVVratio) * 100;
        
        // Calculate the percentage change - this makes percentage positive, to show the increase, if lower
        const percentage = ((lastVVratio - VVratio) / lastVVratio) * 100;
        
        
        // Round the percentage to two decimal places
        const rounded = percentage.toFixed(2);
        
        // Determine the cell color based on the percentage
        const color = percentage > 0 ? '#008A3E' : '#b30000';
        
        // Return the cell data as an object
        return { value: rounded, color: color };
      } else {
        // Return null if the row does not exist
        return null;
        
      }
    };
    // Get the cell data for each row
    const cell30th = getCellData(last30thRow);
    const cell20th = getCellData(last20thRow);
    const cell10th = getCellData(last10thRow);
    const cell5th = getCellData(last5thRow);
    
    // Return the table element with the data
    return (

      <table style={{ borderCollapse: 'collapse', width: '70%', height: '90px'}} border='2px solid black'>
        <thead>
        
          <tr style={{ lineHeight: '2.5' }}>
          
          
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', border: '1.5px solid #2A3F4D'  }}>
          <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 30d ago</Typography>
          </th>

          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', border: '1.5px solid #2A3F4D'  }}>
          <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 20d ago</Typography>
          </th>
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', border: '1.5px solid #2A3F4D'  }}>
          <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 10d ago
          </Typography>
          
          </th>
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', border: '1.5px solid #2A3F4D'  }}>
          <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 5d ago</Typography>
          </th>

          </tr>
        </thead>
        
        <tbody>

            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                
                    {last30thRow ? last30thRow["VVRatio"] : ''}
                </Typography>              
            </td>
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                
                    {last20thRow ? last20thRow["VVRatio"] : ''}
                </Typography>              
            </td>
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                
                    {last10thRow ? last10thRow["VVRatio"] : ''}
                </Typography>              
            </td>
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'}} >
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>                
                    {last5thRow ? last5thRow["VVRatio"] : ''}
                </Typography>              
            </td>

          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ backgroundColor: '#FFFFFF', color: cell30th ? cell30th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}> 
                {cell30th ? cell30th.value + '%' : ''}
                </Typography>            
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: cell20th ? cell20th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {cell20th ? cell20th.value + '%' : ''}
                </Typography>        
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: cell10th ? cell10th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {cell10th ? cell10th.value + '%' : ''}
                </Typography>            
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: cell5th ? cell5th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {cell5th ? cell5th.value + '%' : ''}
                </Typography>        
            </td>
            
          </tr>
          
        </tbody>
      </table>
      

    );
  };
  // Return the renderTable function call
  return renderTable ();
}
export default Get4Percentage;

