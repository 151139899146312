import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function Get4Percentage3PCR () {
  // Define the state variables for the last 15th, last 10th, last 5th

  const [ last15thRow, setLast15thRow ] = useState (null);
  const [ last10thRow, setLast10thRow ] = useState (null);
  const [ last5thRow, setLast5thRow ] = useState (null);  

  
  // Define a new state variable for the last known VVratio
  const [ lastVIXpcr, setLastVIXpcr ] = useState (null);
  const [ lastSPXpcr, setLastSPXpcr ] = useState (null);
  const [ lastEQpcr, setLastEQpcr ] = useState (null);
  
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./cboeVixPCRratio.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        // Get the last 15th, last 10th, and last 5th rows from the data
        //const last30th = results.data [results.data.length - 31];
        const last15threc = results.data[results.data.length - 21];
        const last10threc = results.data[results.data.length - 11];
        const last5threc = results.data[results.data.length - 6];
        const lastrowrec = results.data[results.data.length - 2];

        
        
        // Set the rows to the state

        setLast15thRow (last15threc);
        setLast10thRow (last10threc);
        setLast5thRow (last5threc);

        
        // Set the last known 3x pcr ratios to the state
        setLastVIXpcr (lastrowrec["VIXpcr"]);
        setLastSPXpcr (lastrowrec["Spxpcr"]);
        setLastEQpcr (lastrowrec["Eqpcr"]);
        
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  
  // Define a function to render the table with the rows data
  const renderTable = () => {
    // Define a function to calculate the percentage change and the cell color
    
    const getVixCellData = (row) => {
      // Check if the row exists
      if (row) {
        // Get the value from the row and column
        const value = row["VIXpcr"];
        // Calculate the percentage change
        //const percentage = ((value - lastValue[column]) / lastValue[column]) * 100;
        // Calculate the percentage change
        const percentage = ((lastVIXpcr - value) / lastVIXpcr) * 100;        
        
        // Round the percentage to two decimal places
        const rounded = percentage.toFixed(2);
        
        // Determine the cell color based on the percentage
        const color = percentage > 0 ? '#008A3E' : '#b30000';
        
        // Return the cell data as an object
        return { value: rounded, color: color };
      } else {
        // Return null if the row does not exist
        return null;
      }
    };
    
    const getSPXCellData = (row) => {
      // Check if the row exists
      if (row) {
        // Get the value from the row and column
        const value = row["Spxpcr"];
        // Calculate the percentage change
        //const percentage = ((value - lastValue[column]) / lastValue[column]) * 100;
        // Calculate the percentage change
        const percentage = ((lastSPXpcr - value) / lastSPXpcr) * 100;        
        
        // Round the percentage to two decimal places
        const rounded = percentage.toFixed(2);
        
        // Determine the cell color based on the percentage
        const color = percentage > 0 ? '#008A3E' : '#b30000';
        
        // Return the cell data as an object
        return { value: rounded, color: color };
      } else {
        // Return null if the row does not exist
        return null;
      }
    };

    const getEQCellData = (row) => {
      // Check if the row exists
      if (row) {
        // Get the value from the row and column
        const value = row["Eqpcr"];
        // Calculate the percentage change
        //const percentage = ((value - lastValue[column]) / lastValue[column]) * 100;
        // Calculate the percentage change
        const percentage = ((lastEQpcr - value) / lastEQpcr) * 100;        
        
        // Round the percentage to two decimal places
        const rounded = percentage.toFixed(2);
        
        // Determine the cell color based on the percentage
        const color = percentage > 0 ? '#008A3E' : '#b30000';
        
        // Return the cell data as an object
        return { value: rounded, color: color };
      } else {
        // Return null if the row does not exist
        return null;
      }
    };
    
    // Get the cell data for each row
    
    const VIXpcr_cell15th = getVixCellData(last15thRow);
    const VIXpcr_cell10th = getVixCellData(last10thRow);
    const VIXpcr_cell5th = getVixCellData(last5thRow);
    
    const SPXpcr_cell15th = getSPXCellData(last15thRow);
    const SPXpcr_cell10th = getSPXCellData(last10thRow);
    const SPXpcr_cell5th = getSPXCellData(last5thRow);   

    const EQpcr_cell15th = getEQCellData(last15thRow);
    const EQpcr_cell10th = getEQCellData(last10thRow);
    const EQpcr_cell5th = getEQCellData(last5thRow);
    
 
    // Return the table element with the data
    return (

      <table style={{ borderCollapse: 'collapse', width: '70%', height: '210px'}} border='2px solid black'>
        <thead>
        
          <tr style={{ lineHeight: '2.5' }}>
          
          
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%',  border: '1.5px solid #2A3F4D'  }}>
              <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>Period:
              </Typography>
          </th>
          
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%',  border: '1.5px solid #2A3F4D'  }}>
              <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 20d ago
              </Typography>
          </th>
          
           <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', fontWeight: 'bold', fontSize:'14px' , border: '1.5px solid #2A3F4D' }}>
               <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 10d ago
               </Typography>
           </th>
           
          <th style={{ backgroundColor: 'DodgerBlue', color: 'white', width: '25%', fontWeight: 'bold', fontSize: '14px' , border: '1.5px solid #2A3F4D' }}>
              <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>vs 5d ago
              </Typography>
          </th>

          </tr>
        </thead>
        
        <tbody>
{/* 
comments - VIX pcr
*/}
          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'  }}>
            
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                VIX pcr
                </Typography>
            
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last15thRow ? last15thRow["VIXpcr"] : ''}
                </Typography>  
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {last10thRow ? last10thRow["VIXpcr"] : ''}
                </Typography>      
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last5thRow ? last5thRow["VIXpcr"] : ''}
                </Typography>      
            </td>
            
          </tr>
          
          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ backgroundColor: '#FFFFFF', width: '25%', border: '1.5px solid #2A3F4D'  }}>
            <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}> 
                % 
            </Typography>
            
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: VIXpcr_cell15th ? VIXpcr_cell15th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {VIXpcr_cell15th ? VIXpcr_cell15th.value + '%' : ''}
                </Typography>              
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: VIXpcr_cell10th ? VIXpcr_cell10th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {VIXpcr_cell10th ? VIXpcr_cell10th.value + '%' : ''}
                </Typography>              
            </td>
           
           <td style={{ backgroundColor: '#FFFFFF', color: VIXpcr_cell5th ? VIXpcr_cell5th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
           
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {VIXpcr_cell5th ? VIXpcr_cell5th.value + '%' : ''}
                </Typography>  
            </td>

          </tr>

{/* 
comments - SPX pcr
*/}
          
          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'  }}>
            
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                SPX pcr
                </Typography>
            
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last15thRow ? last15thRow["Spxpcr"] : ''}
                </Typography>  
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {last10thRow ? last10thRow["Spxpcr"] : ''}
                </Typography>      
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last5thRow ? last5thRow["Spxpcr"] : ''}
                </Typography>      
            </td>
            
            
          </tr>
          <tr>
            <td style={{ backgroundColor: '#FFFFFF', width: '25%', border: '1.5px solid #2A3F4D'  }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}> 
                % 
                </Typography>
            
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: SPXpcr_cell15th ? SPXpcr_cell15th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {SPXpcr_cell15th ? SPXpcr_cell15th.value + '%' : ''}
                </Typography>              
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: SPXpcr_cell10th ? SPXpcr_cell10th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {SPXpcr_cell10th ? SPXpcr_cell10th.value + '%' : ''}
                </Typography>              
            </td>
           
           <td style={{ backgroundColor: '#FFFFFF', color: SPXpcr_cell5th ? SPXpcr_cell5th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
           
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {SPXpcr_cell5th ? SPXpcr_cell5th.value + '%' : ''}
                </Typography>  
            </td>

          </tr>
     
{/* 
comments - Eq pcr
*/}

          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D'  }}>
            
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                EQ pcr
                </Typography>
            
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last15thRow ? last15thRow["Eqpcr"] : ''}
                </Typography>  
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>
                {last10thRow ? last10thRow["Eqpcr"] : ''}
                </Typography>      
            </td>
            
            <td style={{ width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {last5thRow ? last5thRow["Eqpcr"] : ''}
                </Typography>      
            </td>
            
            
          </tr>
          
          <tr style={{ lineHeight: '2.5' }}>
          
            <td style={{ backgroundColor: '#FFFFFF', width: '25%', border: '1.5px solid #2A3F4D'  }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}> 
                % 
                </Typography>
            
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: EQpcr_cell15th ? EQpcr_cell15th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {EQpcr_cell15th ? EQpcr_cell15th.value + '%' : ''}
                </Typography>              
            </td>
            
            <td style={{ backgroundColor: '#FFFFFF', color: EQpcr_cell10th ? EQpcr_cell10th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>             
                {EQpcr_cell10th ? EQpcr_cell10th.value + '%' : ''}
                </Typography>              
            </td>
           
           <td style={{ backgroundColor: '#FFFFFF', color: EQpcr_cell5th ? EQpcr_cell5th.color : '#000000', width: '25%', border: '1.5px solid #2A3F4D' }}>
           
                <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 10, sm: 12, md: 14, lg: 16 } }}>            
                {EQpcr_cell5th ? EQpcr_cell5th.value + '%' : ''}
                </Typography>  
            </td>

          </tr>
          
        </tbody>
      </table>

      
    );
  };
  // Return the renderTable function call
  return renderTable ();
}
export default Get4Percentage3PCR;

