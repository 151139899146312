
import { Typography } from '@mui/material';

import AnalysisVix1 from "./AnalysisVix1.js"; 
import AnalysisVVratio1 from "./AnalysisVVratio.js"; 
import AnalysisVT1 from "./AnalysisVT.js"; 
import AnalysisZscore1 from "./AnalysisZscore.js"; 


{/* 
comments 
*/}


const Page0Summary = () => {
return (

    <table style={{ borderCollapse: 'collapse'}} >
    <tbody>
    <tr>
    <td style={{ width: '85%',display: "flex", margin: "auto", alignItems: "center", justifyContent: "center" }} >
    
        <div id="Daily Summary">
            
            <div style={{id:"analysis-vix1", }}>

              <AnalysisVix1 />
              
              <br/>
              
              <AnalysisVVratio1 />
              
              <br/>
              
              <AnalysisVT1 />
              
              <br/>
              
              <AnalysisZscore1 />
              
            </div>
            
        <br/>
        <br/>
        </div>
    </td>
    </tr> 
    </tbody>
    </table>
  );
};
export default Page0Summary;
