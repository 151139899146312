import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function AnalysisZscore1 () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VIXSpreadZscore.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        // Get the last row from the data
        const last = results.data[results.data.length - 2];
        // Set the last row to the state
        setLastRow (last);
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the last row data
  const RenderAnalysis_Zscore1 = () => {
    // Check if the last row exists
        
    if (lastRow) {
      // Define a function to return the daily move
      //const getDailyMove = (vix) => vix / 16;
      
      let zscore1 = lastRow["zscore1"];
      let zscore2 = lastRow["zscore2"];
      let zscore3 = lastRow["zscore3"];
      let zscore4 = lastRow["zscore4"];
      let zscore5 = lastRow["zscore5"];
      
      let zscores = [+zscore1, +zscore2, +zscore3, +zscore4];
      
      // use the spread operator to pass the array elements as arguments
      let zmin = Math.min(...zscores); 
      let zmax = Math.max(...zscores);
      // returns the first index of the element that matches the value
      let zminIndex = zscores.indexOf(zmin); 
      let zmaxIndex = zscores.indexOf(zmax);

      // Initialize an empty message variable
      //let message = "";
      //let message2 = "";
      
      /*
      switch (true) {
        case zscore1 >= X && zscore1 < Y:
          message = "is likely to stay low and markets to remain calm.";
          break;
        case zscore1 >= X && zscore1 < Y:
          message = "is likely to fluctuate and markets may move higher or lower.";
          break;
          
      // Return the div element with the data and the message  
      */
      
      
      return (

        <div>
     
        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">                              
            <span style={{ fontWeight: "bold" }}>{'\u2727'} &nbsp; Zscores Spread Analysis: </span> 
                              
            Comparing the <u>first 4 VIX spreads Zscores</u> (ignoring 5th), the lowest Zscore today is <u>#{zminIndex + 1}</u> with (<u style={{ fontWeight: "bold",color: "Crimson" }}>{zmin}</u>), and the highest is <u>#{zmaxIndex+ 1}</u> with (<u style={{ fontWeight: "bold",color: "Crimson" }}>{zmax}</u>), suggesting that <span style={{ fontWeight: "bold", color: "DarkGreen"  }}>spread #{zminIndex + 1} difference in value is relatively lower than others</span>, likely due to it being "cheaper", or new VIX expiration cycle.
            
            <br />

            {'\u27A2'} &nbsp; Lowest Zscore = VIX Spread #{zminIndex + 1} (VIX {zminIndex + 1}/{zminIndex + 2}) with {zmin} <br />
            
            {'\u27A2'} &nbsp; Highest Zscore = VIX Spread #{zmaxIndex+ 1} (VIX {zmaxIndex + 1}/{zmaxIndex + 2}) with {zmax} <br />

        </Typography>        
        
        </div>

        
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return RenderAnalysis_Zscore1();
}
export default AnalysisZscore1
