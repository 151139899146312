// import React and Chart from the react-google-charts library
import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

//import { ResponsiveContainer } from 'recharts';



{/* 
comments 
*/}



// define a function that takes a chart name as a parameter

export const Chart3b = (chartName) => {
  // use useState hook to store the data for the chart
  const [data, setData] = useState([]);
  // define a function that fetches the data from the json files
  const fetchData = async () => {
    try {
        const response = await fetch("./cboeVixPCRratio.csv");
        
        const data = await response.text();
        // convert the data to the format required by Google Charts
        
        const chartData = [
        //["day","SpxCallVol","SpxPutVol","Spxpcr","SpxCallOI","SpxPutOI","SpxOIpcr"]
        ["day","Call Opt","Put Opt","PCR*1,000,000"]
        
        ];
        
        const lines = data.split("\n");
        
        // get the number of records
        const n = lines.length;
        // get the index of the first record to display
        const firstIndex = n - 60;
        
        for (let i = firstIndex; i < n; i++) {
            // split the line by commas
            const values = lines[i].split(",");
            // parse the values as numbers
            const day = values[0];
            const SpxCallVol = Number(values[7]);
            const SpxPutVol = Number(values[8]);
            const Spxpcr = Number(values[9]);
            //const VIXCallOI = Number(values[4]);
            //const VIXPutOI = Number(values[5]);
            //const SpxOIpcr = Number(values[12]);
            
            // convert the day to a Date object
            const dateObj = new Date(day);
            
            // push the data to the chartData array

            chartData.push([dateObj, SpxCallVol, SpxPutVol,Spxpcr*1000000]);
        }

        // set the data state to the chart data
        setData(chartData);
        } catch (error) {
      console.error(error);
    }
  };
  // use useEffect hook to call the fetch data function when the component mounts
  useEffect(() => {
    fetchData();
  }, []);




  // return the JSX code for the chart component
    return (
        <Chart
            width={"90vw"}
            height={"60vh"}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={data}

            
            options={{

                title: "SPX options traded volume + Put/Call Ratio for past 60days" , 
                Position: 'top',
                //titlePosition: 'in', 
                
                titleTextStyle: {
                fontSize: 12,
                color: 'black', 
                fontName: 'Merriweather'
                //bold: true, 
                //italic: false
                },
                backgroundColor: '#edf3f5', 
                
                hAxis: {
                  title: "Date",
                  titleTextStyle: { color: "#333" },
                  format: 'MMM dd' 
                },
                
                vAxis: { 
                  minValue: 0,
                  textPosition: 'in',  //text appears on chart itself
                  gridlines: {
                    strokeDasharray: '3 3'  // Set dashed gridlines
                  }

                },

                chartArea: { alignment: "center", width: "80%", height: "60%" },
                
                isStacked: true, 
                interpolateNulls: true, 
                // add the seriesType and series options here
                
                chartType: "ComboChart", // set the chart type to combo
                
                
                
                //seriesType: "line", // set the default type to line
                
                series: {
                  0: { type: "area", lineWidth: 2, pointSize: 3, pointShape: 'diamond' },
                  1: { type: "area", lineWidth: 2, pointSize: 3, pointShape: 'diamond' },
                  2: { type: "line",lineWidth: 2, pointSize: 3, pointShape: 'diamond' },
                  //3: { type: "area" , lineWidth: 2 }, 
                  //4: { type: "area" , lineWidth: 2 }, 
                  //5: { type: "line", lineWidth: 2 },
                  
                },
                
                colors: ["#9FB1BD", "#89c7f0", "#574cc2"],

                //MA trendlines
                //series: { 3: { type: 'trendline', algorithm: 'SMA', sourceColumn: 2, period: 20 } },
                
                /*
                trendlines: {
                  0: {
                    type: 'polynomial',
                    degree: 40,
                    color: '48B8F0',
                    opacity: 1,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                  },
                },  
                
                
                  5: {
                    type: 'polynomial',
                    degree: 30,
                    color: 'BB453A',
                    opacity: 0.7,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                  },                     
                },
                */

  
                legend: { 
                    position: "bottom", 
                    textStyle: { fontName: 'Merriweather', fontSize: '10' } , 
                    legendToggle: true
                },
                
                curveType: 'function',
                animation: { startup: true, duration: 1000, easing: 'out' }

                }}
                                
        style={{ margin: "1px" }} 
        />
          
            //</ResponsiveContainer>
    );
};

