import { useState } from 'react';
import './DMtoggle.css';

export const DarkToggle = ({ label, toggled, onClick, toggle }) => {
    
    //const [isToggled, toggle] = useState(toggled)
      const callback = () => {
        toggle(!toggled); // Use the function prop here
        onClick(!toggled);
        document.body.classList.toggle("dark-mode"); 
       //document.getElementById("root").classList.toggle("dark-mode");
      };
      
    return (
        <div id="div-togglebtn" style={{paddingTop:'5px' }} >
            <label>
              <input type="checkbox" Checked={toggled} onClick={callback} />
              <span></span>
              <strong>{label}</strong>
            </label>
         </div>
    )
}
