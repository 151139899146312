import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse

function AnalysisVix1 () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VVRatio.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        // Get the last row from the data
        const last = results.data[results.data.length - 2];
        // Set the last row to the state
        setLastRow (last);
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the last row data
  const RenderAnalysis_Vix1 = () => {
    // Check if the last row exists
        
    if (lastRow) {
      // Define a function to return the daily move
      const getDailyMove = (vix) => vix / 16;
      
      let dailyMove = lastRow["VIX"] / 16;
      let roundedDailyMove = dailyMove.toFixed(2)
      
      // Initialize an empty message variable
      let message = "";
      
      // Use a switch statement to assign a custom message based on the VIX range
      switch (true) {
        case lastRow["VIX"] >= 0 && lastRow["VIX"] < 16:
          message = "is likely to stay low and markets to remain calm.";
          break;
        case lastRow["VIX"] >= 16 && lastRow["VIX"] < 24:
          message = "is likely to fluctuate and markets may move higher or lower.";
          break;
        case lastRow["VIX"] >= 24 && lastRow["VIX"] < 32:
          message = "is likely to remain high and elavated, and may explode to the upside (>32).";
          break;
        case lastRow["VIX"] >= 32:
          message = "will remain high (>32) for quite a while.";
          break;
        default:
          message = "is on holiay.";
      }
      // Return the div element with the data and the message  
      
      
      
      return (

        <div>

        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 14, sm: 16, md: 18, lg: 20  } }}>  
          
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Summary for {lastRow["date"]}</span>
            
        </Typography>
                
        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">                              
            <span style={{ fontWeight: "bold" }}>{'\u2727'} &nbsp;  Vix Analysis: </span> 
            
            With VIX at <u style={{ fontWeight: "bold", color: "Crimson" }}>{lastRow["VIX"]}</u>, volatility <span style={{ fontWeight: "bold", color: "DarkGreen" }}>{message}</span>. The broad indexes ie. S&P500/Nasdaq100 are estimated to go up or down daily by <u style={{ fontWeight: "bold", color: "Crimson" }}>{roundedDailyMove}%</u> in the next ~30 days period. ({lastRow["VIX"]}/16 = {roundedDailyMove}). 
          
            </Typography>
            
        </div>

        
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return RenderAnalysis_Vix1();
}
export default AnalysisVix1
