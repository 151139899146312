import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react'; // Import React and useEffect hook
import Papa from 'papaparse'; // Import Papa Parse


function AnalysisVT1 () {
  // Define the state variable for the last row
  const [ lastRow, setLastRow ] = useState (null);
  // Define a useEffect hook to run once when the component mounts
  useEffect (() => {
    // Define a function to fetch and parse the csv file
    const fetchCsv = async () => {
      // Fetch the csv file from the server
      const response = await fetch ('./VixTermFullwExpiry.csv', { method: 'GET', responseType: 'text' });
      // Check if the response is ok
      if (response.ok) {
        // Get the csv data as text
        const csvData = await response.text ();
        // Parse the csv data with Papa Parse
        const results = Papa.parse (csvData, { header: true });
        // Get the last row from the data
        const last = results.data[results.data.length - 2];
        // Set the last row to the state
        setLastRow (last);
      } else {
        // Handle the error
        console.error ('Error fetching csv file:', response.status, response.statusText);
      }
    };
    // Call the fetchCsv function
    fetchCsv ();
  }, []); // Pass an empty dependency array to run only once
  // Define a function to render the table with the last row data
  const RenderAnalysis_VT1 = () => {
    // Check if the last row exists
        
    if (lastRow) {
      // Define a function to return the daily move
      //const getDailyMove = (vix) => vix / 16;
      
      let VT1 = lastRow["VIX1"];
      let VT2 = lastRow["VIX2"];
      let VT3 = lastRow["VIX3"];
      let VT4 = lastRow["VIX4"];
      let VT5 = lastRow["VIX5"];
      

      // Initialize an empty message variable
      let message = "";

      
      
      switch (true) {
        // 1<2<3<4<5
        case VT1 <= VT2 && VT2 <= VT3 && VT3 <= VT4 && VT4 <= VT5:

          message = "in contango, volatility is likely to stay low with premiums in coming months. VIX1 < VIX2 < VIX3 < VIX4.";
          break;
          
        // 1>2<3<4<5
        case VT1 >= VT2 && VT2 <= VT3 && VT3 <= VT4:
          
          message = "slightly flat, with near term slightly above the 2nd month. Look out for potential direction of volatility ahead. VIX1 > VIX2 < VIX3 < VIX4 ...";
          break;

        // 1>2>3<4<5
        case VT1 >= VT2 && VT2 >= VT3&& VT3 <= VT4:
          
          message = "starting to shape in a backwardation, with near term VIX above the 2nd and 3rd month contracts. Volatility may be spiking in the coming months. VIX1 > VIX2 > VIX3 < VIX4 ...";
          break;
                    
        // 1>2>3>4
        case VT1 >= VT2 && VT2 >= VT3 && VT3 >= VT4:
          
          message = "in backwardardation, suggesting that a recent sell off in markets and a spike in volatility, causing the curve to invert. Stay out of the markets if possible, or look out for potential opportunities. VIX1 > VIX2 > VIX3 > VIX4 ...";
          break;  
       }   
      // Return the div element with the data and the message  
      
      
      
      return (

        <div>
     
        <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15 } }}textAlign="justify">                              
            <span style={{ fontWeight: "bold" }}>{'\u2727'} &nbsp; Term Structure Analysis: </span> 
                              
            Looking at the current VIX term structure, the VIX curve is <span style={{ fontWeight: "bold", color: "DarkGreen"  }}>{message}</span>
            
            <br />



        </Typography>        
        
        </div>

        
      );
    } else {
      // Return null if the last row does not exist
      return null;
    }
  };
  // Return the renderTable function call
  return RenderAnalysis_VT1();
}
export default AnalysisVT1

