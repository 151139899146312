
//Pages
//import MainHome from './PHome.js';
import PageSummary from './PageSummary.js'; 
//import PageC1VVR from './PageC1VVR.js';
//import PageC2ZSC from './PageC2ZSC.js';
//import PageC3PCR from './PageC3PCR.js'; 

//        <PageC1VVR />
//        <PageC2ZSC />
//        <PageC3PCR />     

{/* 
comments 
*/}

const MainHome = () => {
return (
    
    <div id="MainHome">
        <PageSummary />

 

    </div>
        
  );
};
export default MainHome;
