import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

function TradeSystemContent3() {
  return (
        
       
    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
         
        
        <div style={{id:"TradeSystem1desc", textAlign: "Left", justifyContent: "center" }}>

            <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15  } }}textAlign="justify">
                <br />  
                
                Based on the statistics, risk and return values, we determine that the buying of 1 month spread, particular buying the spread at 90 days and closing the position at 60 days before expiration yielded the best risk return. We dig abit further to summarise the returns on a monthly basis from 2013 to 2023.
                      
                With this, the data was collated and processed to test which parameter would work out.
 
                <br />
                <br />
                
                <span style={{ fontStyle: 'italic' }}>
                  *Disclaimer: The research ideas on various VIX trade systems, parameters are just hypothesis, ideas generated based on End-of-day VIX data. Please do your own research before acting on a trade.
                  
                </span> 
                
              </Typography>

        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default TradeSystemContent3;
