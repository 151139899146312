import Typography from '@mui/material/Typography';

import TradeSystemContent1 from "./Info/ATS1-Content1.js"; 
import TradeSystemTable1 from "./ATS1-Table1.js"; 

import TradeSystemContent2 from "./Info/ATS1-Content2.js"; 

import { ResultCompareChart1 } from "./Charts/TradeSysChart1.js";
//import TradeSystemTable2 from "./ATS1-Table2.js"; 


import TradeSystemContent3 from "./Info/ATS1-Content3.js"; 
import TradeSystemTable3 from "./ATS1-Table3.js"; 


//  <PageTradeSystem1desc />
//  <Chart2desc />



const PageD1TradeSystemFull = () => { 
  return (
        

    <div id="PageTradeSystemFull">
    
        <div style={{id:"TradeSystem1desc-title", textAlign: "Left",  textDecoration: "underline",  paddingLeft: "8%" }}>

            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 14, sm: 16, md: 18, lg: 20 } }}>
            
            <span style={{ fontWeight: "bold" }}> VIX trading system: </span> 
            
            </Typography>

        </div>
        
        
        <div style={{id:"PageTradeSystem1-content", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <TradeSystemContent1 />
      
            <br/>

        </div>
        
        
        <div style={{id:"TradeSystemSummary1-title", textAlign: "Left",  textDecoration: "underline", paddingLeft: "8%" }}>
        
        <br/>           
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            Summary comparison of various parameters on VIX trade spread system
            
            </Typography>
            
            <br/>      
        </div>
               
        <div style={{id:"TradeSystemSummary1-table", display: "flex", textAlign: "left", alignItems: "left", justifyContent: "left", paddingLeft: "8%" }}>
            
            <TradeSystemTable1 />
          
            <br/>
            <br/>
        </div>
        
        <div style={{id:"TradeSystemResults2-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <TradeSystemContent2 />
      
            <br/>

        </div>
        
        <br/> 
        
        <div style={{id:"TradeSystemPnl2-title", textAlign: "Left",  textDecoration: "underline", paddingLeft: "8%" }}>
        
        <br/>           
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            PnL Comparison of VIX trade system across 5 different parameters (2013-2023)
            
            </Typography>
            
        </div>
            
        <div style={{id:"TradeSystemPnl2-chart", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
        
            <ResultCompareChart1 chartName="Comparison across 5 different VIX trade systems" />
            
        </div>
        
        <br/>
        <br/>
        
        <div style={{id:"TradeSystemResults3-title", textAlign: "Left",  textDecoration: "underline",  paddingLeft: "8%" }}>

            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 14, sm: 16, md: 18, lg: 20 } }}>
            
            <span style={{ fontWeight: "bold" }}>Best system: Returns of 1-mth VIX spread from 90 to 60 DTE.</span>
            
            </Typography>

        </div>  
    
        <div style={{id:"TradeSystemResults3-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <TradeSystemContent3 />
      
            <br/>

        </div>
        
    
        <div style={{id:"TradeSystemResults3-table", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "left", paddingLeft: "8%" }}>
            
            <TradeSystemTable3 />
          
            <br/>
            
        </div>
        
        
        
    </div>         

  );
}
export default PageD1TradeSystemFull;
