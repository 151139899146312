

import { Typography } from '@mui/material';

import { Chart1 } from "./Charts/ChartComponents.js";
import DisplayChart1Data from "./Info/Displaylatestdata.js"; 
import Get4Percentage from "./Info/Get4Percentage.js"; 

import Chart1desc from "./Info/Chart1desc.js"; 

{/* 
comments 
*/}


const PageC1VVR = () => {
return (
    

    <div id="PageVVR">
        
        <div style={{id:"chart1title", textAlign: "Left",  textDecoration: "underline", paddingLeft: "10%" }}>
            
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            1. Chart - A volatility measure of VIX - VVIX/VIX RATIO (VVRATIO)
            
            </Typography>
            

        </div>
        
        <div style={{id:"chart1", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart1 chartName="CBOE VIX and VVIX Index" />
            
        </div>
        
        <br/>
        <br/>
        
        <div style={{id:"chart1-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

            <DisplayChart1Data />
            
        </div>
        
        <br/>
        
        <div style={{id:"chart1-get4pct", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

          <Get4Percentage />
          
        </div>
        
        <div style={{id:"chart1-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
          <Chart1desc />

        </div>
        <br/>
        <br/>
        <br/>

    </div>
  
  );
};
export default PageC1VVR;
